






























































































































































































import { Component, Vue } from 'vue-property-decorator'
import QrComponent from '@/views/Group/TopPage/MemberList/QR/Qr.vue'
import { EGroupAuth, Member, UserProfile } from '@/models'
import GroupService from '@/services/GroupService'
import GroupMemberService from '@/services/GroupMemberService'
import PreviewText from '@/helpers/PreviewText'
import { updateRoleInvite } from '@/helpers/UpdateGroupUserRole'

/**
 * f2-102a
 * グループ QRコード招待リンク・招待権限の付与
 */
@Component({ components: { QrComponent } })
export default class InviteAuth extends Vue {
  private memberList: MemberViewModel[] = []
  private currentLink: string = ''
  private checkAll: boolean = false
  private selectedMembers: Member[] = []
  private page: number = 1
  private maxPage: number = 1
  private noAvatar = require('@/assets/images/avatar-default.png')
  private limit: number = this.$store.state.setting.setting.setting_display
    .number_item_lists
  private isAdmin: boolean = false
  private isUncheckMe: boolean = false

  async created() {
    this.curentUserRoleAdmin()
    await this.getAllMember()
    this.getCurrentInviteLink()
    this.handleSelectedMembers()
  }

  curentUserRoleAdmin() {
    this.isAdmin = this.$store.state.userInfo.user.group_role.role_admin
  }

  hanldeCheckAll() {
    this.memberList.map(item => {
      if (!item.isAdmin) {
        item.selected = this.checkAll
      }
    })
    this.selectedMembers = this.memberList.filter(item => item.selected)
  }

  handleSelectedMembers() {
    this.selectedMembers = this.memberList.filter(item => item.selected)
    this.checkAll = this.memberList.every(item => item.selected)
  }

  async getAllMember(loadMore?: boolean) {
    return GroupMemberService.getAllMembersByGroupId(
      this.$route.params.groupId,
      this.page,
      this.limit
    )
      .then(res => {
        if (res.status === 200) {
          const data = res.data.data.map(
            (item: any) => new MemberViewModel(new Member(item))
          )
          if (loadMore) {
            this.memberList = [...this.memberList, ...data]
          } else {
            this.memberList = data
          }
          this.selectedMembers = this.memberList.filter(item => item.selected)
          this.maxPage = res.data.last_page
        }
      })
      .catch(err => {
        this.$bvModal.show('modal-error-invite-auth')
      })
  }

  /**
   * Call API for loadmore
   */
  loadMore() {
    ++this.page
    this.getAllMember(true)
    this.checkAll = false
  }

  getCurrentInviteLink() {
    GroupService.getGroupById(this.$route.params.groupId, ['group_link'])
      .then(res => {
        if (res.status === 200)
          this.currentLink = `${window.location.origin}/group/${res.data.group_link}/preview`
      })
      .catch(err => {
        this.$bvModal.show('modal-error-invite-auth')
      })
  }

  backToQrPage() {
    if (this.isUncheckMe) {
      this.$router.push({ name: 'group-toppage' })
      updateRoleInvite(false)
    } else {
      this.$router.push({ name: 'group-members-qr-code-invite' })
    }
  }

  backToQrPageRoute() {
    if (this.isUncheckMe) {
      return ({ name: 'group-toppage' })
    } else {
      return ({ name: 'group-members-qr-code-invite' })
    }
  }

  jumpToInviteLink() {
    this.$router.push({
      name: 'group-members-qr-code-invite',
      params: {
        isInviteByMail: 'false',
        step: '1'
      }
    })
  }

  jumpToInviteMail() {
    this.$router.push({
      name: 'group-members-qr-code-invite',
      params: {
        isInviteByMail: 'true',
        step: '2'
      }
    })
  }

  jumpToMembersRole() {
    this.$router.push({ name: 'group-members-role' })
  }

  /**
   * Update role
   */
  async handleUpdate() {
    //get selected member permission
    const res = await GroupMemberService.listMembersRole(
      this.$route.params.groupId,
      this.selectedMembers.map(item => item.id)
    )
    if (res.status !== 200) return

    //selected member but not admin
    let selectedMembers: Member[] = res.data
      .map((item: any) => new Member(item))
      .filter((item: Member) => !item.role.includes(EGroupAuth.ADMIN))

    // member has role invite in the start (checked)
    const rootRole = this.memberList.filter(
      item =>
        item.role.includes(EGroupAuth.INVITE) &&
        !item.role.includes(EGroupAuth.ADMIN)
    )

    //new check member
    const newCheckMember = selectedMembers
      .filter(item => !rootRole.map(i => i.id).includes(item.id))
      .map(i => {
        i.role = [...i.role, EGroupAuth.INVITE]
        return {
          user_id: i.id,
          permission_ids: i.role
        }
      })

    //uncheck check member
    const unCheckMember = rootRole
      .filter(item => !selectedMembers.map(i => i.id).includes(item.id))
      .map(i => {
        i.role = i.role.filter(r => r !== EGroupAuth.INVITE)

        //set uncheck me to redirect when update success
        this.isUncheckMe = i.id === this.$store.state.userInfo.user.info.id

        return {
          user_id: i.id,
          permission_ids: i.role
        }
      })

    //update member. new check member will have role invite, uncheck member wont have rile invite
    this.$blockui.show()
    GroupMemberService.updateMembersRole({
      group_id: Number(this.$route.params.groupId),
      member_roles: [...newCheckMember, ...unCheckMember]
    })
      .then(res => {
        if (res.status === 200) {
          this.$bvModal.show('modal-success')
        }
      })
      .catch(() => {
        this.$bvModal.show('modal-error')
      })
      .finally(() => this.$blockui.hide())
  }

  getFullName(profile?: UserProfile) {
    const name = [
      profile?.first_name,
      profile?.last_name,
      String(this.$t('groups.member_list.name_suffix'))
    ].join(' ')
    const preview = PreviewText.covertToPreviewText(name, 15)
    return {
      name: name,
      text: preview.text,
      isLong: preview.isLong
    }
  }
}

class MemberViewModel extends Member {
  selected: boolean = false

  constructor(init: Member) {
    super()
    Object.assign(this, init)
    this.selected =
      init.role.includes(EGroupAuth.ADMIN) ||
      init.role.includes(EGroupAuth.INVITE)
  }
}

interface MemberIdRole {
  id: number
  role: number[]
}
